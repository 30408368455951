<template>
    <v-dialog v-model="isActive"
              :value="true"
              max-width="450"
              width="100%"
              transition="dialog-bottom-transition"
    >
        <v-toolbar color="secondary">
            <v-toolbar-title class="white--text">
                Scheda Evacuatore
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <template v-if="hasPermission && actionScheda">
                <v-btn small icon @click="remove_evacuatore()" color="white">
                    <v-icon small>fas fa-trash</v-icon>
                </v-btn>
                <v-btn small icon @click="isEditing = !isEditing" color="white" class="ml-3">
                    <v-icon small>fas fa-edit</v-icon>
                </v-btn>
            </template>
            <v-btn small icon @click="$emit('closeScheda')" color="white" class="ml-3 mr-1">
                <v-icon small>fas fa-times</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card outlined>
            <v-form v-model="formValid" ref="form_data" lazy-formValidation>
                <v-container>
                    <v-layout column>
                        <Schede :id_stabilimento="id_stabilimento" @setIdScheda="setIdScheda" :selected="scheda_selected" :disabled="!isEditing" :isMaster="hasPermission" class="mb-5" :actionScheda="actionScheda"/>
                        <v-text-field label="Matricola"
                                      :readonly="!isEditing"
                                      v-model="matricola"
                                      required
                                      :rules="isEditing ? matricolaRules : requiredRules"/>
                        <v-text-field autocomplete="off"
                                      label="Anno"
                                      :readonly="!isEditing"
                                      type="number"
                                      min="1700"
                                      max="3000"
                                      v-model="anno"
                                      required
                                      :rules="annoRules" />
                        <v-select v-if="stabilimento && stabilimento.reparti"
                                  :items="stabilimento.reparti"
                                  :readonly="!isEditing"
                                  v-model="reparto"
                                  item-text="nome"
                                  item-value="_id"
                                  label="Seleziona un reparto" />
                        <v-textarea label="Note"
                                    :readonly="!isEditing"
                                    v-model="note"
                                    outlined
                                    auto-grow
                                    rows="1" />
                        <div @click="stampaQR('https://app.lacelle.it?qr=' + matricola)" style="width:100%" ref="qr_container" class="qr_print" id="qr_print">
                            <QRcode v-if="matricola" :value="'https://app.lacelle.it?qr=' + matricola" :width="qrWidth" :quality="1" :margin="2" class="qr_print"/>
                        </div>
                    </v-layout>
                </v-container>
            </v-form>
            <v-card-actions>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="4" offset-md="4" sm="12" class="pt-0">
                            <v-btn color="primary" width="100%" @click="submit" :disabled="!formValid || loading" v-text="isEditing ? 'Salva' : 'Indietro'"></v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import apiEvacuatori from '@/js/pages/evacuatori';
import { requiredRules, matricolaRules, annoRules } from '@/js/validationRules';
const Schede = () => import('@/components/Schede');
import QRcode from 'vue-qrcode';

export default {
    name: 'newEvacuatore',
    components: {
        Schede,
        QRcode
    },
    props: [
        '_id',
        'id_stabilimento',
        'hasPermission',
        'actionScheda'
    ],
    data() {
        return {
            token: this.$cookies.get('token'),
            anno: '',
            matricola: '',
            reparto: '',
            note: '',
            id_scheda: '',
            formValid: false,
            requiredRules: requiredRules,
            matricolaRules: matricolaRules,
            annoRules: annoRules,
            loading: false,
            isEditing: false,
            scheda_selected: undefined,
            isActive: true,
            qrWidth: 409,
            stabilimento: undefined,
        }
    },
    watch: {
        isActive(){
            this.$emit('closeScheda');
            let those = this;
            setTimeout(() => {
                those.isActive = true;
            }, 100);
        }
    },
    async created() {
        let evacuatore = await apiEvacuatori.getOne(this.token, this._id);
        this.matricola = evacuatore.matricola || '';
        this.anno = evacuatore.anno || '';
        this.reparto = evacuatore.id_reparto || '';
        this.note = evacuatore.note || '';
        this.scheda_selected = evacuatore.id_scheda;
        if (this.id_stabilimento) {
            let stabilimento = await apiEvacuatori.getStabilimento(this.id_stabilimento);
            if (stabilimento)
                this.stabilimento = stabilimento;
        }
    },
    mounted() {
        if(
            this.$refs &&
            this.$refs.qr_container &&
            this.$refs.qr_container.offsetWidth
        ) this.qrWidth = this.$refs.qr_container.offsetWidth - 10;
    },
    methods: {
        async submit(){
            if(this.isEditing) {
                this.loading = true;
                if(
                    this.$refs.form_data.validate() &&
                    this.formValid
                ){
                    let data = {
                        _id: this._id,
                        note: this.note,
                        anno: this.anno,
                        matricola: this.matricola,
                        id_scheda: this.id_scheda,
                        id_reparto: this.reparto,
                    };
                    await apiEvacuatori.updateOne(this.token, data);
                    this.$emit('closeScheda','reloadEvacuatori');
                }
                this.loading = false;
            } else this.$emit('closeScheda');
        },
        async setIdScheda(id_scheda) {
            this.id_scheda = id_scheda;
        },
        async remove_evacuatore(){
            if(confirm("Eliminare l'evacuatore?")){
                let data = { _id: this._id };
                await apiEvacuatori.deleteOne(this.token, data);
                this.$emit('closeScheda', 'reloadEvacuatori');
            }
        },
        stampaQR(data) {
            var mywindow = window.open('', 'PRINT', 'height=400,width=600');

            mywindow.document.write('<html><head><title>Stampa QR</title>');
            mywindow.document.write('</head><body>');
            //mywindow.document.write('<h1>' + document.title  + '</h1>');
            mywindow.document.write(document.getElementById('qr_print').innerHTML);
            mywindow.document.write('<br><span style="padding-left:120px;font-size:35px;">'+this.matricola+'</span>');
            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.onafterprint = function(){ mywindow.close(); }
            setTimeout(() => {
                mywindow.document.ready = mywindow.print();
            }, 400);

            return true;
        },
    },
}
</script>